<template>
  <div class="my-second info bgcolor">
      <Head :title="$t('info.change_login_psd')" :show="true" ></Head>
      <div class="common-box info-box">
          <div class="pass-main">
                <van-form @submit="onSubmit" class="pass-form" ref="passform">
                    <van-cell-group class="form-box ">
                        <van-field
                        v-model="obj.password"
                        :type="pt3"
                        name="password"
                        :right-icon="pt3=='password'?'eye':'closed-eye'"
                        :label="$t('info.old_psd')"
                        :placeholder="$t('login.enter')+ $t('info.old_psd')"
                         @click-right-icon="changePassType3"
                        :rules="[{ required: true, message: $t('login.required_pass') },
                            {pattern:passPattern,message:$t('login.pass_error'),trigger:'onBlur'}
                        ]"
                    />
                    <van-field
                        v-model="obj.newPassword"
                        :type="pt2"
                        name="newPassword"
                        @blur="validatorPass"
                        :right-icon="pt2=='password'?'eye':'closed-eye'"
                        :label="$t('info.new_psd')"
                        :placeholder="$t('login.enter')+$t('login.login_pass')+$t('login.pass_rule')"
                         @click-right-icon="changePassType2"
                        :rules="[
                        { required: true, message: $t('login.required_pass') },
                        {
                            pattern:passPattern,message:$t('login.pass_error'),trigger:'onBlur'
                        }]"
                    />
                    <van-field
                        v-model="obj.newPasswordrepeat"
                        :type="pt1"
                        name="newPasswordrepeat"
                        :right-icon="pt1=='password'?'eye':'closed-eye'"
                        :label="$t('info.con_new_psd')"
                        :placeholder="$t('info.con_new_psd')"
                         @click-right-icon="changePassType1"
                        :rules="[
                            { required: true, message: $t('login.required_dbpass') },
                            {
                                pattern:passPattern,message:$t('login.pass_error'),trigger:'onBlur'
                            },
                            {
                                validator:validatordbPass,message:$t('login.pass_equal_error'),trigger:'onBlur'
                            }
                        ]"
                    />
                    </van-cell-group>
                </van-form>
                <van-button class="login-btn" @click="changepass">{{$t('common.confirm')}}</van-button>
          </div>
      </div>
  </div>
</template>

<script>
import {passReg} from "@/common/validate"
import {Notify} from "vant"
export default {
    data(){
        return {
            obj:{},
            pt3:"password",
            pt2:"password",
            pt1:"password",
            passPattern:passReg,
        }
    },
    methods:{
        validatorPass(val){
            if(this.obj.newPasswordrepeat){
                this.$refs.passform.validate('newPasswordrepeat');
            }
        },
        validatordbPass(val){
            if(this.obj.newPassword){
                return val === this.obj.newPassword;
            }  
        },
        changepass(){
            this.$refs.passform.submit();
        },
        changePassType3(){
            this.pt3 = this.pt3 == "password"?"text":"password"
        },
        changePassType2(){
            this.pt2 = this.pt2 == "password"?"text":"password"
        },
        changePassType1(){
            this.pt1 = this.pt1 == "password"?"text":"password"
        },
        onSubmit(value){
            this.$ajax.edituserpassword(value)
            .then(res=>{
                if(res.code==200){
                    // 重置登录密码 
                    Notify({ type: 'success', message: res.msg });
                    // 重新登录 
                    // this.$router.go(-1)  
                }
            })
        }
    }
}
</script>
